import React from 'react';
import { createContainer } from "unstated-next";
import { AuthService } from '../service/AuthService';
import { NotValiedAccount, UbiqAuthUserBase, UbiqUser, UbiqUserBase } from '../type/type';
import { User } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from '../App';
import { merge } from 'lodash';

const useUserState = () => {

  const authService = new AuthService();
  const auth = authService.getAuth();

  const [user, setUser] = React.useState<UbiqUser | NotValiedAccount | null>(null);

  const getAdminUserIDAndAuthorities = async (authID: string) => {
    const docSnap = await getDoc(doc(db, "authes", authID));
    if (!docSnap.exists()) return null;
    const userIDs = docSnap.data().userIDs;
    const allowedUserIDs: { userID: string, authorities: string[] }[] = [];
    for (const userID of userIDs) {
      const authoritiesSnap = await getDocs(query(collection(db, "adminAuthorities"), where("userIDs", "array-contains", userID)));
      const permissionNames = authoritiesSnap.docs.map(e => e.id);
      if (permissionNames.length > 0) {
        allowedUserIDs.push({ userID: userID, authorities: permissionNames });
      }
    }
    if (allowedUserIDs.length <= 0) return null;
    return allowedUserIDs[0];
  };

  const createUbiqUserObj = async (authUserData: User, UserIDAndAuthorities: { userID: string, authorities: string[] }) => {
    const userID = UserIDAndAuthorities.userID;
    const authorities = UserIDAndAuthorities.authorities ?? [];
    const docSnap = await getDoc(doc(db, "users", userID));
    if (!docSnap.exists()) return null;
    const pickedAuthUserData: UbiqAuthUserBase = {
      email: authUserData.email,
      phoneNumber: authUserData.phoneNumber,
      authID: authUserData.uid,
      userID: userID,
      authorities: authorities
    };
    const userData = docSnap.data() as UbiqUserBase;
    const user: UbiqUser = merge(pickedAuthUserData, userData);
    return user;
  };

  authService.onAuthStateChanged(async user => {
    // authIDと
    const authID = user?.uid;
    if (user === undefined || user === null || authID === undefined || authID === null) {
      setUser(null);
    } else {
      const adminUid = await getAdminUserIDAndAuthorities(authID);
      if (adminUid === undefined || adminUid === null) {
        setUser({ userID: "invailedaccount" } as NotValiedAccount);
      } else {
        setUser(await createUbiqUserObj(user, adminUid));
      }
    }
  });

  const signOut = async () => { await authService.signOut(); }

  return { user, signOut, auth }
}

const userContainer = createContainer(useUserState);

export default userContainer;