import { ReactNode, useState } from "react";
import InvalidAccountErrorComponent from "../components/InvalidAccountErrorComponent";
import LoginComponent from "../components/LoginComponent";
import userContainer from "../container/UserContainer";
import { Menu as MenuIcon, Mail, CheckCircle, Home, ExitToApp } from '@mui/icons-material';
import { AppBar, Avatar, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ThemeProvider, Toolbar, Typography, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { theme } from "../theme/theme";

const BasePage = ({ children }: {
  children: ReactNode
}) => {
  const userState = userContainer.useContainer();

  const [drawerOpened, setDrawerOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutDialogOpened, setDialogOpened] = useState(false);

  return (
    <>
      {
        userState.user
          ?
          (userState.user?.userID === "invailedaccount"
            ?
            <InvalidAccountErrorComponent />
            :
            <>
              <div>
                <Drawer
                  anchor={'left'}
                  open={drawerOpened}
                  onClose={() => setDrawerOpened(false)}>
                  <List>
                    <ListItem>
                      <Link to="/" style={{ color: "black", backgroundColor: 'transparent', textDecoration: 'none' }} >
                        <ListItemButton>
                          <ListItemIcon sx={{ color: "black" }}><Home /></ListItemIcon>
                          <ListItemText primary="ホーム" primaryTypographyProps={{
                            fontWeight: 'medium'
                          }} />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Link to="/dm" style={{ color: "black", backgroundColor: 'transparent', textDecoration: 'none' }} >
                        <ListItemButton>
                          <ListItemIcon sx={{ color: "black" }}><Mail /></ListItemIcon>
                          <ListItemText primary="DM" primaryTypographyProps={{
                            fontWeight: 'medium'
                          }} />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/badge" style={{ color: "black", backgroundColor: 'transparent', textDecoration: 'none' }} >
                        <ListItemButton>
                          <ListItemIcon sx={{ color: "black" }}><CheckCircle /></ListItemIcon>
                          <ListItemText primary="公認バッジ" primaryTypographyProps={{
                            fontWeight: 'medium'
                          }} />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/biq" style={{ color: "black", backgroundColor: 'transparent', textDecoration: 'none' }} >
                        <ListItemButton>
                          <ListItemIcon sx={{ color: "black" }}><CheckCircle /></ListItemIcon>
                          <ListItemText primary="biq" primaryTypographyProps={{
                            fontWeight: 'medium'
                          }} />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  </List>
                </Drawer>
                <ThemeProvider theme={theme}>
                  <Dialog
                    open={logoutDialogOpened}
                    onClose={() => { setDialogOpened(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"ログアウトしますか？"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        保存されていない変更は破棄されます
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button sx={{ color: "text.primary" }} onClick={() => { setDialogOpened(false) }}>キャンセル</Button>
                      <Button sx={{ color: "error.main" }} onClick={userState.signOut} autoFocus>
                        ログアウト
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <AppBar position="static">
                    <Toolbar>
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setDrawerOpened(true)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Ubiq Admin Console
                      </Typography>
                      <div>
                        <IconButton
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={(event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); }}
                          color="inherit"
                        >
                          {
                            userState.user.iconUrl
                              ?
                              <Avatar alt={userState.user.name} src={userState.user.iconUrl} />
                              :
                              <Avatar>{userState.user.name}</Avatar>
                          }
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorEl)}
                          onClose={() => { setAnchorEl(null); }}
                        >
                          <MenuItem>
                            <ListItemText primary={`${userState.user.name} (@${userState.user.shortID})`} sx={{ color: "black" }} primaryTypographyProps={{
                              fontWeight: 'medium'
                            }} />
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={() => { setAnchorEl(null); setDialogOpened(true); }}>
                            <ListItemIcon sx={{ color: "black" }}><ExitToApp /></ListItemIcon>
                            <ListItemText sx={{ color: "black" }} primary="ログアウト" primaryTypographyProps={{
                              fontWeight: 'medium'
                            }} />
                          </MenuItem>
                        </Menu>
                      </div>
                    </Toolbar>
                  </AppBar>
                  <main>
                    {children}
                  </main>
                </ThemeProvider>
              </div>
            </>)
          :
          (<LoginComponent />)
      }
    </>
  );
}
export default BasePage;
