import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(255, 60, 118)'
    },
    error: {
      main: "#ff0000",
    },
  },
});