import {
  Alert,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../App";
import BasePage from "../common/basePage";
import { isEmptyList } from "../utils/isEmptyList";
import { onedayIncentiveGrantQueueStatus } from "../utils/onedayIncentiveGrantQueueStatus";
import { ymd } from "../utils/ymd";

const ManageBiqPage = () => {
  const [user, setUser] = useState<any>();
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  const [inputShortID, setInputShortID] = useState("");
  const [inputBiq, setInputBiq] = useState<number>(0);

  const handleInputShortIDChange = (event: any) => {
    setInputShortID(event.target.value);
    setUserNotFound(false); // falseにしないとユーザーが存在しませんと表示されたまま
    setUser(null); // nullにしないとuserが表示されたまま
  };
  const handleInputBiqChange = (event: any) => {
    setInputBiq(event.target.value);
  };

  const handleGiveBiq = async () => {
    // console.log(`BIQが付与されました: ${inputBiq}`);
    if (inputBiq < 0) {
      throw Error("0未満の金額をインセンティブとして与えることはできません");
    }
    const biqAmountRounded = Math.round(inputBiq);
    // console.log(biqAmountRounded);
    // inputShortIDとdate(yyyy/mm/dd)で検索
    // 認証しているユーザーなら取ってこれる
    const onedayIncetiveGrantQueueDoc = await getDocs(
      query(
        collection(db, "onedayIncetiveGrantQueue"),
        where("shortID", "==", inputShortID),
        where("date", "==", ymd())
      )
    );

    // console.log(onedayIncetiveGrantQueueDoc.docs);
    if (isEmptyList(onedayIncetiveGrantQueueDoc.docs)) {
      // なかったらcreate
      const onedayIncetiveGrantQueue: any = {
        amount: biqAmountRounded,
        receiverID: inputShortID,
        status: onedayIncentiveGrantQueueStatus.waiting,
        date: Timestamp.fromDate(ymd()),
      };
      // onedayIncetiveGrantQueueという名前のコレクションにドキュメントを作成する
      await addDoc(
        collection(db, "onedayIncetiveGrantQueue"),
        onedayIncetiveGrantQueue
      );
    } else {
      // あったらupdate
      const docID = onedayIncetiveGrantQueueDoc.docs[0].id;
      const docRef = doc(db, "onedayIncetiveGrantQueue", docID);
      await updateDoc(docRef, {
        amount: increment(biqAmountRounded),
      });
    }

    // フィールドのクリア
    setInputBiq(0);
  };

  useEffect(() => {
    const getUser = async () => {
      if (!inputShortID) {
        return;
      }

      const user = await getDocs(
        query(collection(db, "users"), where("shortID", "==", inputShortID))
      );

      if (user.docs.length === 0) {
        setUserNotFound(true);
        setUser(null);
        return;
      }

      const doc = user.docs[0];
      const data = doc.data();
      setUser(data);
    };

    getUser();
  }, [inputShortID]);

  return (
    <BasePage>
      <Box sx={{ m: 4 }}>
        <Alert severity="info" sx={{ mb: 4 }}>
          biq を管理できるようにする
        </Alert>
        <TextField
          label="shortID"
          value={inputShortID}
          onChange={handleInputShortIDChange}
          sx={{ mb: 2 }}
        />
        <Button onClick={() => setInputShortID("")} sx={{ mb: 4 }}>
          Clear
        </Button>
        <TableContainer component={Paper} sx={{ width: "calc(100vw - 64px)" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Users</TableCell>
                <TableCell align="right">付与biq</TableCell>
                <TableCell align="right">付与</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userNotFound ? (
                <TableRow>
                  <TableCell colSpan={2}>ユーザーが存在しません</TableCell>
                </TableRow>
              ) : (
                user && (
                  <TableRow
                    key={user.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {user.name}
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        label="biq"
                        value={inputBiq}
                        onChange={handleInputBiqChange}
                        sx={{ mb: 2 }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={handleGiveBiq} sx={{ mb: 4 }}>
                        付与
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </BasePage>
  );
};

export default ManageBiqPage;
