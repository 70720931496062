import { Timestamp } from 'firebase/firestore';
import { Merge } from 'type-fest';

export enum Env {
  dev,
  product
}

// ユーザー
export type UbiqUserBase = {
  iconUrl: string | null,
  coverUrl: string | null,
  name: string,
  bio: string,
  place: string,
  job: string,
  userLink: string,
  followCount: number,
  followerCount: number,
  shortID: string,
  isVerified: boolean,
  isPrivate: boolean,
  isBot: boolean,
  didUseCreatorInvitationCode: boolean,
  badge: number,
  createdAt: Timestamp,
  updatedAt: Timestamp
};

export type UbiqAuthUserBase = {
  email: string | null,
  phoneNumber: string | null,
  authID: string,
  userID: string,
  authorities: string[]
};

export type UbiqUser = Merge<UbiqUserBase, UbiqAuthUserBase>

export interface NotValiedAccount extends UbiqUser {

}

// DM
export type RawTalkroom = {
  id: string,
  joinUserIDs: string[],
  userID1: string,
  userID2: string,
  notReadMessageAmountUser1: number,
  notReadMessageAmountUser2: number,
  lastReadAtUser1: Timestamp,
  lastReadAtUser2: Timestamp,
  latestMessageID: string | null,
  latestMessageType: number | null,
  latestMessageBody: string | null,
  isAdmin: boolean | null | undefined,
  talkroomUrl: string,
  createdAt: Timestamp,
  updatedAt: Timestamp
};

export type Talkroom = {
  id: string,
  joinUserIDs: string[],
  notReadMessageAmount: number,
  notReadMessageAmountTarget: number,
  lastReadAt: Timestamp,
  lastReadAtTarget: Timestamp,
  latestMessageID: string | null,
  latestMessageType: number | null,
  latestMessageBody: string | null,
  isAdmin: boolean | null | undefined,
  talkroomUrl: string,
  createdAt: Timestamp,
  updatedAt: Timestamp,
  targetUserData: UbiqUser | null,
  isBlocked: boolean,
  isUser1isAdmin: boolean
};

export type Message = {
  type: number,
  senderID: string,
  aspectRatio?: number | null | undefined,
  imageUrl?: string | null | undefined,
  text?: string | null | undefined,
  createdAt: Timestamp,
  updatedAt: Timestamp
};