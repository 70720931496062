import React from 'react';
import userContainer from '../container/UserContainer';
import { Alert, Box, Button } from '@mui/material';

const InvalidAccountErrorComponent = () => {
  const userState = userContainer.useContainer();
  return (
    <Box sx={{ m: 4 }}>
      <Alert severity="error">必要な権限がありません [login]</Alert>
      <Button sx={{ mt: 2 }} onClick={userState.signOut} variant="contained">ログインし直す</Button>
    </Box >
  );
}

export default InvalidAccountErrorComponent