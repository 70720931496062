import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import BasePage from "../common/basePage";
import styled from "@emotion/styled";
import userContainer from "../container/UserContainer";
import { useEffect, useState } from "react";
import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  Unsubscribe,
  updateDoc,
  addDoc,
  Timestamp,
  increment,
} from "firebase/firestore";
import { functions, db } from "../App";
import { officialAccountUserID } from "../config/config";
import {
  Message,
  RawTalkroom,
  Talkroom,
  UbiqAuthUserBase,
  UbiqUser,
  UbiqUserBase,
} from "../type/type";
import { merge, truncate, orderBy as lodashOrderBy } from "lodash";
import { Close, Send } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { httpsCallable } from "firebase/functions";
import InvalidAccountErrorComponent from "../components/InvalidAccountErrorComponent";
import { checkTextIsNotEmpty } from "../utils/utils";

const MessageList = styled(List)({});

const userListWidth = "280px";

let beforeRealtimeListennerDetacher: Unsubscribe | null = null;
let tmpTalkroom: Talkroom[] | null = null;
let selectedTalkroomData: Talkroom = {} as Talkroom;

const DMPage = () => {
  const userState = userContainer.useContainer();

  const [previewImageModalIsOpen, setPreviewImageModalIsOpen] = useState(false);
  const [previewImageSrc, setPreviewImageSrc] = useState("");

  const MessageListItem = ({ message }: { message: Message }) => {
    if (message.type === 2)
      setPreviewImageSrc(message.imageUrl ? message.imageUrl : "");
    return (
      <ListItem
        {...(message.senderID === officialAccountUserID && {
          style: {
            display: "flex",
            justifyContent: "flex-end",
          },
        })}
      >
        <Box sx={{ display: "flex", flexFlow: "column" }}>
          <Box
            sx={{ color: "black" }}
            {...(message.senderID === officialAccountUserID
              ? {
                  style: {
                    padding: "16px",
                    color: "white",
                    borderRadius: "16px 16px 0 16px",
                    backgroundColor: "#3579f3",
                  },
                }
              : {
                  style: {
                    padding: message.type === 1 ? "16px" : "0",
                    color: "white",
                    borderRadius: "16px 16px 16px 0",
                    backgroundColor: message.type === 1 ? "#787879" : "#e3e3e6",
                  },
                })}
          >
            {message.type === 1 &&
              message.text
                ?.split("\n")
                .map((text) => <Typography>{text}</Typography>)}
            {message.type === 2 && (
              <img
                src={message.imageUrl ? message.imageUrl : ""}
                alt=""
                onClick={() => setPreviewImageModalIsOpen(true)}
                width="200px"
                {...(message.senderID === officialAccountUserID
                  ? {
                      style: {
                        borderRadius: "16px 16px 0 16px",
                      },
                    }
                  : {
                      style: {
                        borderRadius: "16px 16px 16px 0",
                      },
                    })}
              />
            )}
          </Box>
          <Typography
            sx={{ color: "gray", fontSize: "0.8rem", fontWeight: "bold" }}
            {...(message.senderID === officialAccountUserID && {
              style: {
                textAlign: "right",
              },
            })}
          >
            {message.createdAt.toDate().toLocaleDateString("ja-JP") +
              " " +
              message.createdAt.toDate().toLocaleTimeString("ja-JP")}
          </Typography>
        </Box>
      </ListItem>
    );
  };

  // トークルーム処理
  const [chattingTalkrooms, setChattingTalkrooms] = useState([] as Talkroom[]);
  const makeAndSetChattingTalkrooms = async (
    querySnap: QuerySnapshot<DocumentData>
  ) => {
    let talkrooms: Talkroom[] = [];
    for (const d of querySnap.docs) {
      // firebaseから取得してきたtalkroomは扱いにくいので、いい感じに変換する
      const rawTalkroom = d.data() as RawTalkroom;
      const isUser1isAdmin = rawTalkroom.userID1 === officialAccountUserID;
      const targetUserID = isUser1isAdmin
        ? rawTalkroom.userID2
        : rawTalkroom.userID1;
      const userDataSnap = await getDoc(doc(db, "users", targetUserID));
      const userDataBase = userDataSnap.data() as UbiqUserBase;
      const userAuthData: UbiqAuthUserBase = {
        email: "",
        phoneNumber: null,
        authID: "",
        userID: targetUserID,
        authorities: [],
      };
      const userData: UbiqUser = merge(userDataBase, userAuthData);
      const targetUserBlockSnap = await getDoc(
        doc(db, "users", targetUserID, "block", "block")
      );
      const targetBlockingUserIDs: string[] =
        targetUserBlockSnap.data()?.blockingUserIDs ?? [];
      const isBlocked = targetBlockingUserIDs.includes(officialAccountUserID);
      const talkroom: Talkroom = {
        id: rawTalkroom.id,
        joinUserIDs: rawTalkroom.joinUserIDs,
        notReadMessageAmount: isUser1isAdmin
          ? rawTalkroom.notReadMessageAmountUser1
          : rawTalkroom.notReadMessageAmountUser2,
        notReadMessageAmountTarget: isUser1isAdmin
          ? rawTalkroom.notReadMessageAmountUser2
          : rawTalkroom.notReadMessageAmountUser1,
        lastReadAt: isUser1isAdmin
          ? rawTalkroom.lastReadAtUser1
          : rawTalkroom.lastReadAtUser2,
        lastReadAtTarget: isUser1isAdmin
          ? rawTalkroom.lastReadAtUser2
          : rawTalkroom.lastReadAtUser1,
        latestMessageID: rawTalkroom.latestMessageID,
        latestMessageType: rawTalkroom.latestMessageType,
        latestMessageBody: rawTalkroom.latestMessageBody,
        isAdmin: rawTalkroom.isAdmin,
        talkroomUrl: rawTalkroom.talkroomUrl,
        createdAt: rawTalkroom.createdAt,
        updatedAt: rawTalkroom.updatedAt,
        targetUserData: userData,
        isBlocked: isBlocked,
        isUser1isAdmin: isUser1isAdmin,
      };
      talkrooms.push(talkroom);
    }
    talkrooms = lodashOrderBy(
      talkrooms,
      ["notReadMessageAmount", "lastReadAt"],
      ["desc", "desc"]
    );
    tmpTalkroom = talkrooms;
    setChattingTalkrooms(talkrooms);
  };
  const seachTalkroom = (searchText: string) => {
    if (tmpTalkroom != null) {
      setChattingTalkrooms(tmpTalkroom);
    }
    if (searchText !== null && searchText !== undefined && searchText !== "") {
      setChattingTalkrooms(
        chattingTalkrooms.filter(
          (r) =>
            r.targetUserData?.name.includes(searchText) ||
            r.targetUserData?.shortID.includes(searchText)
        )
      );
    }
  };
  useEffect(() => {
    (async () => {
      const q = query(
        collection(db, "talkrooms"),
        where("joinUserIDs", "array-contains", officialAccountUserID)
      );
      onSnapshot(q, async (querySnapshot) => {
        // チャットアップデート
        await makeAndSetChattingTalkrooms(querySnapshot);
      });
    })();
  }, []);

  // メッセージ処理
  const [messages, setMessages] = useState([] as Message[]);
  const updateTalkroomLastReadInfo = async (talkroom: Talkroom) => {
    const updateFields = talkroom.isUser1isAdmin
      ? {
          notReadMessageAmountUser1: 0,
          lastReadAtUser1: new Date(),
        }
      : {
          notReadMessageAmountUser2: 0,
          lastReadAtUser2: new Date(),
        };
    await updateDoc(doc(db, "talkrooms", talkroom.id), updateFields);
  };
  const fetchTalkroomMessages = async (talkroom: Talkroom) => {
    if (beforeRealtimeListennerDetacher != null)
      beforeRealtimeListennerDetacher();
    const q = query(
      collection(db, "talkrooms", talkroom.id, "messages"),
      orderBy("createdAt", "asc")
    );
    beforeRealtimeListennerDetacher = onSnapshot(
      q,
      async (talkroomMessagesSnap) => {
        setTimeout(async () => {
          await updateTalkroomLastReadInfo(selectedTalkroomData);
        }, 1000);
        setMessages([]);
        setMessages(talkroomMessagesSnap.docs.map((e) => e.data() as Message));
      }
    );
  };
  const openTalkroom = async (talkroom: Talkroom) => {
    selectedTalkroomData = talkroom;
    // 既読時間アップデート
    await updateTalkroomLastReadInfo(talkroom);
    // メッセージロード
    await fetchTalkroomMessages(talkroom);
  };

  // 返信
  const [replyText, setReplyText] = useState("");
  const onClickReplyButton = async () => {
    //
    const message: Message = {
      type: 1,
      senderID: officialAccountUserID,
      aspectRatio: null,
      imageUrl: null,
      text: replyText,
      createdAt: Timestamp.fromDate(new Date()),
      updatedAt: Timestamp.fromDate(new Date()),
    };
    await addDoc(
      collection(db, "talkrooms", selectedTalkroomData.id, "messages"),
      message
    );
    //
    const updateFields = selectedTalkroomData.isUser1isAdmin
      ? {
          notReadMessageAmountUser2: increment(1),
        }
      : {
          notReadMessageAmountUser1: increment(1),
        };
    await updateDoc(
      doc(db, "talkrooms", selectedTalkroomData.id),
      updateFields
    );
    //
    setReplyText("");
  };

  // 一斉送信
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const onModalClose = () => {
    return;
  };
  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [confirmSendDmDialogOpened, setConfirmSendDmDialogOpenedModalIsOpen] =
    useState(false);
  const [isSendingDm, setIsSendingDm] = useState(false);
  const [dmTextBody, setDmTextBody] = useState("");
  const sendOfficialDirectMessage = httpsCallable(
    functions,
    "onCall-sendOfficialDirectMessage"
  );
  const onStartSending = () => {
    setIsSendingDm(true);
  };
  const onFinishSending = () => {
    setIsSendingDm(false);
    setDmTextBody("");
    setModalIsOpen(false);
  };
  const sendDM = async () => {
    onStartSending();
    try {
      const res = await sendOfficialDirectMessage({
        callUserID: userState.user?.userID,
        messageText: dmTextBody,
        messageImageUrl: null,
        messageImageAspectRatio: null,
      });
      type ErrorRes = {
        error: { errorType: string | null; message: string; status: string };
      };
      type SuccessRes = { response: { message: string } };
      const successResData = res.data as SuccessRes;
      if (successResData.response === undefined) {
        const errorResData = res.data as ErrorRes;
        alert(errorResData.error.message);
      }
    } catch (e) {
      onFinishSending();
      alert(e);
    }
    onFinishSending();
  };

  return (
    <BasePage>
      <Modal
        open={modalIsOpen}
        onClose={onModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <IconButton
            sx={{ mb: 1 }}
            onClick={() => {
              !isSendingDm && setModalIsOpen(false);
            }}
            {...(isSendingDm && { disabled: true })}
          >
            <Close />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            DM一斉送信
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            公式アカウント (@ubiq)
            から全ての公認クリエイターにメッセージが送信されます
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            id="standard-basic"
            label="テキストを入力"
            variant="standard"
            multiline
            fullWidth
            rows={5}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDmTextBody(event.target.value);
            }}
          />
          <Alert
            severity="info"
            sx={{ mt: 2 }}
            {...(isSendingDm
              ? { style: { display: "flex" } }
              : { style: { display: "none" } })}
          >
            送信完了まで画面を閉じないでください
          </Alert>
          <LoadingButton
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setConfirmSendDmDialogOpenedModalIsOpen(true)}
            {...(isSendingDm && { loading: true })}
            {...(checkTextIsNotEmpty(dmTextBody)
              ? { disabled: false }
              : { disabled: true })}
          >
            <span>送信</span>
          </LoadingButton>
        </Box>
      </Modal>
      <Dialog
        open={confirmSendDmDialogOpened}
        onClose={() => {
          setConfirmSendDmDialogOpenedModalIsOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"送信してよろしいですか？"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            メッセージが送信されます
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "text.primary" }}
            onClick={() => {
              setConfirmSendDmDialogOpenedModalIsOpen(false);
            }}
          >
            キャンセル
          </Button>
          <Button
            sx={{ color: "error" }}
            onClick={async () => {
              setConfirmSendDmDialogOpenedModalIsOpen(false);
              await sendDM();
            }}
            autoFocus
          >
            送信
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={previewImageModalIsOpen}
        onClose={() => setPreviewImageModalIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box>
          <img src={previewImageSrc} alt="" height="80%" />
        </Box>
      </Modal>
      {userState.user?.authorities ? (
        userState.user?.authorities.includes("dm") ? (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `${userListWidth} 1fr`,
            }}
          >
            <Box
              sx={{ bgcolor: "#f5f5f5", boxShadow: 3 }}
              style={{ height: "calc(100vh - 64px)" }}
            >
              <Box sx={{ m: 1, mt: 2 }}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setModalIsOpen(true)}
                >
                  DM一斉送信
                </Button>
                <TextField
                  id="filled-search"
                  label="ユーザーを検索"
                  type="search"
                  variant="outlined"
                  sx={{ mt: 2, bgcolor: "#ffffff" }}
                  style={{ width: `calc(${userListWidth} - 16px)` }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    seachTalkroom(event.target.value);
                  }}
                />
              </Box>
              <List>
                {chattingTalkrooms.map((room: Talkroom) => (
                  <ListItem disablePadding key={room.id}>
                    <ListItemButton
                      onClick={async () => await openTalkroom(room)}
                    >
                      <ListItemIcon sx={{ color: "black" }}>
                        <Badge
                          badgeContent={room.notReadMessageAmount}
                          color="primary"
                        >
                          <Avatar
                            src={
                              room.targetUserData
                                ? room.targetUserData.iconUrl
                                  ? room.targetUserData.iconUrl
                                  : ""
                                : ""
                            }
                          />
                        </Badge>
                      </ListItemIcon>
                      <Box sx={{ display: "flex", flexFlow: "column" }}>
                        <ListItemText
                          sx={{ color: "black", m: 0 }}
                          primary={
                            room.targetUserData
                              ? room.targetUserData.name
                              : "削除済みのユーザーです"
                          }
                        />
                        <ListItemText
                          sx={{ color: "black" }}
                          primaryTypographyProps={{
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                          }}
                          primary={truncate(room.latestMessageBody ?? "", {
                            length: 20,
                            omission: "...",
                          })}
                        />
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                width: "100%",
                height: "calc(100vh - 64px)",
              }}
            >
              {selectedTalkroomData.id === undefined ? (
                <Box sx={{ m: 4 }}>トークルームが選択されていません</Box>
              ) : (
                <Box
                  sx={{ m: 4, mt: 0 }}
                  style={{ width: "70%", height: "100vh" }}
                >
                  <Box
                    sx={{
                      ml: 1,
                      mt: 2,
                      mb: 2,
                      position: "sticky",
                      top: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Avatar
                        src={
                          selectedTalkroomData.targetUserData
                            ? selectedTalkroomData.targetUserData.iconUrl
                              ? selectedTalkroomData.targetUserData.iconUrl
                              : ""
                            : ""
                        }
                      />
                    </Box>
                    <Box sx={{ ml: 2, display: "flex", flexFlow: "column" }}>
                      <Typography sx={{ fontSize: "1.2rem" }}>
                        {selectedTalkroomData.targetUserData?.name}
                      </Typography>
                      <Typography sx={{ fontSize: "1rem" }}>
                        @{selectedTalkroomData.targetUserData?.shortID}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      height: "calc(100vh - 64px - 128px - 32px - 32px - 16px)",
                    }}
                  >
                    <MessageList
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "scroll",
                      }}
                    >
                      {messages.map((message: Message) => (
                        <MessageListItem
                          message={message}
                          key={message.senderID + message.createdAt.seconds}
                        />
                      ))}
                    </MessageList>
                  </Box>
                  <Box
                    sx={{
                      ml: 1,
                      mt: 4,
                      mb: 2,
                      position: "sticky",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      alignItems: "end",
                    }}
                  >
                    {selectedTalkroomData.isBlocked ? (
                      <Alert severity="error">
                        ブロックされているため返信できません
                      </Alert>
                    ) : (
                      <>
                        <TextField
                          value={replyText}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setReplyText(event.target.value);
                          }}
                          id="standard-basic"
                          label="@ubiqから返信"
                          variant="standard"
                          sx={{ backgroundColor: "white" }}
                          multiline
                          fullWidth
                          rows={2}
                        />
                        <Box>
                          <IconButton
                            aria-label="delete"
                            onClick={async () => await onClickReplyButton()}
                            {...(checkTextIsNotEmpty(replyText)
                              ? { disabled: false }
                              : { disabled: true })}
                          >
                            <Send />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ m: 4 }}>
            <Alert severity="error">必要な権限がありません [dm]</Alert>
          </Box>
        )
      ) : (
        <InvalidAccountErrorComponent />
      )}
    </BasePage>
  );
};
export default DMPage;
