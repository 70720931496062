import BasePage from "../common/basePage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Alert, Box, TextField, Button } from "@mui/material";
import { Switch } from "@mui/material";
import { db } from "../App";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import userContainer from "../container/UserContainer";
import InvalidAccountErrorComponent from "../components/InvalidAccountErrorComponent";

const ManageBadgePage = () => {
  const [shortID, setShortID] = useState("");
  const [user, setUser] = useState<any>();
  const [checked, setChecked] = useState<boolean>(false);
  const [ref, setRef] = useState<any>();
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  const userState = userContainer.useContainer();

  useEffect(() => {
    const getUser = async () => {
      if (!shortID) {
        // shortID が空の場合は何もしない
        return;
      }

      // query()によりクエリを作成し、getDocs()により実行
      const user = await getDocs(
        query(collection(db, "users"), where("shortID", "==", shortID))
      );

      if (user.docs.length === 0) {
        setUserNotFound(true);
        setUser(null); // ユーザーが存在しない場合は user ステートを null に設定
        setChecked(false); // ユーザーが存在しない場合は checked ステートを false に設定
        return;
      }

      const doc = user.docs[0];
      // ドキュメントのReference
      const ref = doc.ref;
      setRef(ref);
      const data = doc.data();
      setUser(data); // ユーザーが存在する場合は user ステートを更新
      setChecked(data.badge); // ユーザーが存在する場合は checked ステートを更新
    };

    getUser();
  }, [shortID]);

  const handleChange = async () => {
    if (!ref) {
      return;
    }

    setChecked((prevChecked) => !prevChecked);
    const newChecked = !checked ? 1 : 0;
    try {
      await updateDoc(ref, {
        badge: newChecked,
      });

      const badgeHistoryRef = doc(ref, "badgeHistories", "badgeHistories"); // badgeHistoriesドキュメントの参照
      // ドキュメントが取得できているかの確認 snapshotをawait getDocで取得し、data()で中身をみている
      console.log((await getDoc(badgeHistoryRef)).data());
      await updateDoc(badgeHistoryRef, {
        grantedAts: arrayUnion(new Date()),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleShortIDChange = (event: any) => {
    setShortID(event.target.value);
    setUser(null);
    setUserNotFound(false);
    setChecked(false);
  };

  return (
    <BasePage>
      {userState.user?.authorities ? (
        userState.user?.authorities.includes("badge") ? (
          <Box sx={{ m: 4 }}>
            <Alert severity="info" sx={{ mb: 4 }}>
              公認バッジを管理できるようにする
            </Alert>
            <TextField
              label="shortID"
              value={shortID}
              onChange={handleShortIDChange}
              sx={{ mb: 2 }}
            />
            <Button onClick={() => setShortID("")} sx={{ mb: 4 }}>
              Clear
            </Button>
            <TableContainer
              component={Paper}
              sx={{ width: "calc(100vw - 64px)" }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Users</TableCell>
                    <TableCell align="right">公認バッジ</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userNotFound ? (
                    <TableRow>
                      <TableCell colSpan={2}>ユーザーが存在しません</TableCell>
                    </TableRow>
                  ) : (
                    user && (
                      <TableRow
                        key={user.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.name}
                        </TableCell>
                        <TableCell align="right">
                          <Switch checked={checked} onChange={handleChange} />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box sx={{ m: 4 }}>
            <Alert severity="error">必要な権限がありません [badge]</Alert>
          </Box>
        )
      ) : (
        <InvalidAccountErrorComponent />
      )}
    </BasePage>
  );
};
export default ManageBadgePage;
