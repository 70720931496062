import { Env } from "../type/type";

const env = Env.product;
export const officialAccountUserID = env.toString() === Env.dev.toString() ? "hyO0OD2G54UiQTnxzkSP" : "dmF77bS0pItQYuDYs4uv";
export const credentials = env.toString() === Env.dev.toString() ? {
  apiKey: "AIzaSyBA1uqxPK4NIpe-GlUzIApzElign7GYH_k",
  authDomain: "ubiq-dev.firebaseapp.com",
  projectId: "ubiq-dev",
  storageBucket: "ubiq-dev.appspot.com",
  messagingSenderId: "819938810830",
  appId: "1:819938810830:web:0141b5d516a6fcf7eb699f",
  measurementId: "G-B8ZZBDZTZ8"
} : {
  apiKey: "AIzaSyCCF8Icm_lpyCw8k4aVdJp1byLzL7W201g",
  authDomain: "ubiq-7c998.firebaseapp.com",
  projectId: "ubiq-7c998",
  storageBucket: "ubiq-7c998.appspot.com",
  messagingSenderId: "323662742899",
  appId: "1:323662742899:web:2926c25d073f6c6f327106",
  measurementId: "G-EG2L8G86NH"
};