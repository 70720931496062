import { User, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../App";

export class AuthService {

  onAuthStateChanged = (observer: (user: User | null) => void) => {
    onAuthStateChanged(auth, observer);
  }

  signOut = async () => {
    await signOut(auth);
  }

  getAuth = () => auth
}