import React from 'react';
import userContainer from "../container/UserContainer";

import { StyledFirebaseAuth } from 'react-firebaseui';
import { EmailAuthProvider } from 'firebase/auth';
import { Box, Typography } from '@mui/material';

const loginUiConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect', // サインイン時にリダイレクトするかポップアップを利用するか
  signInSuccessUrl: window.location.href, // サインイン後に帰ってくるURL
  signInOptions: [EmailAuthProvider.PROVIDER_ID], // 利用する認証プロバイダー
};

const LoginComponent = () => {
  const userState = userContainer.useContainer();
  return (
    <div>
      {
        userState.user
          ?
          <></>
          :
          <Box>
            <Box sx={{ m: 2 }} style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant='h5'>ようこそ！Ubiqアカウントでサインインしてください</Typography>
            </Box>
            <StyledFirebaseAuth uiConfig={loginUiConfig} firebaseAuth={userState.auth} />
          </Box>
      }
    </div >
  );
}

export default LoginComponent