import { Alert, Box } from "@mui/material";
import BasePage from "../common/basePage";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type StudyData = {
  date: string;
  問題数: number;
  正解数: number;
  正解率: number;
};

const studyDataList: StudyData[] = [
  {
    date: '10/01',
    問題数: 120,
    正解数: 105,
    正解率: 88,
  },
  {
    date: '10/02',
    問題数: 130,
    正解数: 110,
    正解率: 85,
  },
  {
    date: '10/03',
    問題数: 125,
    正解数: 96,
    正解率: 77,
  },
  {
    date: '10/04',
    問題数: 100,
    正解数: 85,
    正解率: 85,
  },
  {
    date: '10/05',
    問題数: 110,
    正解数: 88,
    正解率: 80,
  },
  {
    date: '10/06',
    問題数: 120,
    正解数: 92,
    正解率: 77,
  },
  {
    date: '10/07',
    問題数: 135,
    正解数: 109,
    正解率: 80,
  },
  {
    date: '10/08',
    問題数: 0,
    正解数: 0,
    正解率: 0,
  },
  {
    date: '10/09',
    問題数: 105,
    正解数: 96,
    正解率: 91,
  },
  {
    date: '10/10',
    問題数: 115,
    正解数: 98,
    正解率: 85,
  },
  {
    date: '10/11',
    問題数: 125,
    正解数: 107,
    正解率: 86,
  },
  {
    date: '10/12',
    問題数: 50,
    正解数: 48,
    正解率: 96,
  },
  {
    date: '10/13',
    問題数: 130,
    正解数: 114,
    正解率: 88,
  },
  {
    date: '10/14',
    問題数: 120,
    正解数: 109,
    正解率: 90,
  },
  {
    date: '10/15',
    問題数: 130,
    正解数: 124,
    正解率: 95,
  },
  {
    date: '10/16',
    問題数: 140,
    正解数: 124,
    正解率: 89,
  },
  {
    date: '10/17',
    問題数: 20,
    正解数: 20,
    正解率: 100,
  },
  {
    date: '10/18',
    問題数: 135,
    正解数: 112,
    正解率: 83,
  },
  {
    date: '10/19',
    問題数: 100,
    正解数: 90,
    正解率: 90,
  },
];

const TopPage = () => {
  return (
    <BasePage>
      <Box sx={{ m: 4 }}>
        <Alert severity="info" sx={{ mb: 4 }}>いい感じに統計を表示する</Alert>
        <Box>
          <LineChart
            width={700}
            height={300}
            data={studyDataList}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis dataKey="問題数" />
            <Line type="monotone" dataKey="問題数" stroke="#8884d8" />
            <Line type="monotone" dataKey="正解数" stroke="#3ba2f6" />
            <Line type="monotone" dataKey="正解率" stroke="#ff0092" />
            <Legend />
            <Tooltip />
          </LineChart>
        </Box>
      </Box>
    </BasePage>
  )
}
export default TopPage