import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { credentials } from "./config/config";
import userContainer from "./container/UserContainer";
import DMPage from "./pages/DMPage";
import ManageBadgePage from "./pages/ManageBadgePage";
import ManageBiqPage from "./pages/ManageBiqPage";
import TopPage from "./pages/TopPage";

const initilizedFirebaseApp = initializeApp(credentials);
export const db = getFirestore(initilizedFirebaseApp);
export const functions = getFunctions(initilizedFirebaseApp, "asia-northeast1");
export const auth = getAuth(initilizedFirebaseApp);

function App() {
  return (
    <userContainer.Provider>
      <BrowserRouter>
        <Routes>
          <Route index element={<TopPage />} />
          <Route path="badge" element={<ManageBadgePage />} />
          <Route path="dm" element={<DMPage />} />
          <Route path="biq" element={<ManageBiqPage />} />
        </Routes>
      </BrowserRouter>
    </userContainer.Provider>
  );
}

export default App;
